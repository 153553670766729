
body {
    font-family: 'Poppins';
    font-style: normal;
}

.container-menu {
    position: absolute;
    width: 1789px;
    height: 80px;
    left: 130px;
    top: 40px;
}

.titleMobile {
    display: none;
}

.h1-custom{
    //position: absolute;
    width: 274px;
    height: 38px;
    left: 210px;
    top: 59px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    /* identical to box height, or 158% */
    letter-spacing: -0.02em;

    //color: #000000;
}

.h1-area{
// width:1080px;
// height: 80px;
margin-left: px;
margin-top: -30px;
background: #FFFFFF;
}

.icon-custom-white{
    color: #FFFFFF;
    font-size: 30px;
    opacity: 0.9;
}

.btn-box-left{
    background: #2266CC;
    border-radius: 6px;
}

.box{
    width: 70px;
    height: 60px;
    left: 710px;
    background: #F3F6F9;
    border-radius: 11px;
    padding-bottom: -10px;
}

.txt-center{
text-align: center;
line-height: 60px;
font-size: 20px;
}

.blue-card {
 background-color: #2266CC;
}

.top-icon {
    background-color: #2266CC;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -20px;
    left: 25px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-icon-vacancy {
    width: 50px;
    height: 100px;
    position: absolute;
    top: -20px;
    left: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-top{
    font-size: 30px;
    color: #FFFFFF;
    // opacity: 0.7;
}

.dark-blue-card{
    background: #0E2445;
    color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 0px 20px 20px;
    font-size: 16px;
}

.txt-box{
    top: 518px;
    font-size: 15px;
    margin: 0;
    margin-left: 20px;
    font-family: 'Poppins';
    font-style: normal;
}

.txt-box-primary{
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
}

.btn-red{
    background: #E22242;
    color: #FFFFFF;
}
.card-text {
    font-weight: 500 !important;
    font-size: 20px;
}
.card {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-weight: 500;
    font-size: 15px;
    padding: 10px 10px 8px;
}

.inputSearchIcon {
    padding: 16px;
}

@media (max-width: 768px) {
    .titleMobile {
        display: initial;
        margin-top: 12px;
    }

    .titleMobile h3 {
        font-size: 24px !important;
    }

    .inputSearch {
        padding: 18px;
        font-size: 16px !important;
    }

    .inputSearchIcon {
        padding: 22px;
    }

    .btnNewuserMobile {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }

    .tableWeb {
        display: none;
    }
}
body {
	font-family: 'Poppins';
	font-style: normal;
}

.gridTemplateTableForMobile {
	display: initial;
}

.container-menu {
	position: absolute;
	width: 1789px;
	height: 80px;
	left: 130px;
	top: 40px;
}
.h1-custom {
	//position: absolute;
	width: 274px;
	height: 38px;
	left: 210px;
	top: 59px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 38px;
	/* identical to box height, or 158% */
	letter-spacing: -0.02em;

	//color: #000000;
}

.h1-area {
	// width:1080px;
	// height: 80px;
	margin-left: px;
	margin-top: -30px;
	background: #ffffff;
}

.icon-custom-white {
	color: #ffffff;
	font-size: 30px;
	opacity: 0.9;
}

.btn-box-left {
	background: #2266cc;
	border-radius: 6px;
}

.box {
	height: 60px;
	left: 710px;
	background: #f3f6f9;
	border-radius: 11px;
	padding-bottom: -10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.txt-center {
	text-align: center;
	line-height: 60px;
	font-size: 20px;
}

.blue-card {
	background-color: #2266cc;
	height: 230px;
}

.top-icon {
	background-color: #2266cc;
	width: 50px;
	height: 50px;
	position: absolute;
	top: -20px;
	left: 35px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.top-icon-vacancy {
	padding-bottom: 5px;
}

.icon-top {
	font-size: 30px;
	color: #ffffff;
	//opacity: 0.7;
}
.card {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	font-weight: 500;
	font-size: 15px;
	padding: 10px 10px 8px;
	height: 230px;
}
.card2 {
	height: 710px;
	padding: 5px 5px 0px;
	font-weight: 500;
	font-size: 15px;
}
.dark-blue-card {
	background: #153e7b;
	color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	//padding: 0px 5px 5px;
	font-size: 16px;
	height: 230px;
}

.txt-box {
	top: 518px;
	font-size: 15px;
	margin: 0;
	margin-left: 20px;
	font-family: 'Poppins';
	font-style: normal;
}

.txt-box-primary {
	font-weight: 600;
	font-size: 15px;
	line-height: 21px;
}

.text-inline {
	display: inline-block;
	justify-content: space-between;
	padding: 10px;
	//word-wrap: normal;
}

.badge {
	display: inline-block;
	font-size: 11px;
	font-weight: 600;
	padding: 3px 6px;
	border: 1px solid transparent;
	min-width: 10px;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	border-radius: 99999px;
}

.badge.badge-primary {
	background-color: #2196f3;
}

.badge.badge-outlined.badge-primary {
	border-color: #2196f3;
	color: #2196f3;
}

.badge-preview {
	padding-top: 60px;
	padding-bottom: 10px;
	margin: auto;
	text-align: center;
}
.badge-preview .badge {
	margin-right: 10px;
}

.th-custom {
	background-color: #4683de;
	text-align: center;

	//border-radius: 10px 10px 0px 0px;
	h4 {
		color: #ffffff;
	}
}

.td-custom {
	text-align: center;
	color: #000000;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
}
.table2 {
	min-width: 10px !important;
	overflow: scroll !important;
}
.table {
	width: 100%;
}

.titleMobile {
	display: none;
}

@media only screen and (max-width: 900px) {
	.card2 {
		overflow: auto;
		max-height: 710px;
		padding: 25px 0 0 0;
		font-weight: 500;
		font-size: 15px;
		max-width: fit-content;
	}
	.top-icon-scroll {
		margin-top: 25px;
	}
	.gridTemplateTableForMobile {
		display: none;
	}
	.HideTableMobile {
		display: none;
	}
	.btnMobile {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 50px;
		font-size: 16px !important;
		margin-bottom: 16px;
	}
	.inputMobile {
		font-size: 16px !important;
		padding: 16px !important;
	}
	.iconSearchMobile {
		padding: 12px;
	}
	.titleMobile {
		display: initial;
		font-size: 24px !important;
	}
	.titleMobile h3 {
		font-size: 24px !important;
	}
	.subTitlePageMobile {
		font-size: 16px;
	}
}

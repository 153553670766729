@media only screen and (max-width: 600px) {
    .Step {
        width: 30px;
    }
}

@media (max-width: 900px) {
    .formFull {
        width: 100%;
    }
    .inputForm {
        padding: 16px;
        font-size: 16px;
    }
    .iconMobile {
        margin-top: -40px !important;
    }
    .btnMobile {
        padding: 22px !important;
    }
}
body {
	font-family: 'Poppins';
	font-style: normal;
}

.container-menu {
	position: absolute;
	width: 1789px;
	height: 80px;
	left: 130px;
	top: 40px;
}

.btn-red {
	background: #bf1231;
	color: #ffff;
	border-radius: 5%;
	i {
		color: #ffff;
	}
}
.btn-red:hover {
	background: blue !important;
	color: #ffff;
}

.h1-custom {
	//position: absolute;
	width: 274px;
	height: 38px;
	left: 210px;
	top: 59px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 38px;
	/* identical to box height, or 158% */
	letter-spacing: -0.02em;

	//color: #000000;
}

.h1-area {
	// width:1080px;
	// height: 80px;
	margin-left: px;
	margin-top: -30px;
	background: #ffffff;
}

.icon-custom-white {
	color: #ffffff;
	font-size: 40px;
	opacity: 0.9;
}

.btn-box-left {
	background: #2266cc;
	border-radius: 6px;
}

.box {
	height: 60px;
	left: 710px;
	min-width: 70px;
	background: #f3f6f9;
	border-radius: 11px;
	padding-bottom: -10px;
}

.box-aside {
	// height: 60px;
	// left: 710px;
	background: #f3f6f9;
	border-radius: 10px;

	width: 65px;
	height: 55px;
}

.txt-center {
	text-align: center;
	line-height: 60px;
	font-size: 16px;
	font-weight: 500;
}

.blue-card {
	background-color: #2266cc;
}

.top-icon {
	background-color: #2266cc;
	width: 50px;
	height: 50px;
	position: absolute;
	top: -20px;
	left: 25px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.top-icon-vacancy {
	width: 50px;
	height: 100px;
	position: absolute;
	top: -20px;
	left: 15px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-top {
	font-size: 30px;
	color: #ffffff;
	//opacity: 0.7;
}

.dark-blue-card {
	background: #153e7b;
	color: #ffffff;
	height: 215px;
}

.txt-box {
	top: 518px;
	font-size: 15px;
	margin: 0;
	margin-left: 20px;
	font-family: 'Poppins';
	font-style: normal;
}

.txt-box-primary {
	font-weight: 600;
	font-size: 15px;
	line-height: 21px;
}

.text-inline {
	display: inline-block;
	justify-content: space-between;
	padding: 10px;
	//word-wrap: normal;
}

::-webkit-scrollbar {
	/* width of vertical scrollbar */
	border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-thumb:horizontal {
	background: #2266cc;
	border-radius: 10px;
}

.btn-white {
	background: #ffffff;
	color: #2266cc;
}

.th-custom {
	background-color: #4683de;
	text-align: center;
	padding-left: 10px;
	//border-radius: 10px 10px 0px 0px;
	h4 {
		color: #ffffff;
	}
}

.td-custom {
	text-align: center;
	color: #000000;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
}

.input-filter {
	box-sizing: border-box;
	width: 472px;
}
.card {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	font-weight: 500;
	font-size: 15px;
	padding: 10px 10px 8px;
}

.card2 {
	height: 695px;
	padding: 5px 5px 0px;
	font-weight: 500;
	font-size: 15px;
}
.blue-card {
	background-color: #2266cc;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 10px 10px 20px;
	height: 210px;
	font-size: 16px;
	display: flex;
	justify-content: center;
	width: 100%;
}
.dark-blue-card {
	background: #153e7b;
	color: #ffffff;
}

.txt-box {
	top: 518px;
	font-size: 15px;
	margin: 0;
	margin-left: 20px;
	font-family: 'Poppins';
	font-style: normal;
}

.text-aberta {
	color: #28a745;
	font-weight: bold;
	font-size: 12px;
}

.text-fechada {
	color: red;
	font-weight: bold;
	font-size: 12px;
}

.table2 {
	min-width: 10px !important;
	overflow: scroll !important;
}

.table {
	width: 100%;
}

.mobileTitle {
	display: none;
}

@media only screen and (max-width: 900px) {
	.card {
		margin-bottom: 0px !important;
		margin-top: 30px;
	}
	.blue-card {
		margin-top: 30px !important;
		margin-bottom: 30px !important;
	}
	.dark-blue-card {
		margin-bottom: 0px !important;
	}

	.mobileTitle {
		display: initial;
		font-size: 22px;
		margin-top: 12px;
		margin-bottom: 12px;
	}

	.mobileInput {
		padding: 16px;
		font-size: 16px;
	}

	.mobileIcon {
		padding: 22px;
	}

	.table2 {
		display: none;
	}
}

.ContainerAuth {
background-color: black;
width: 100% !important;
height: 100%;
}
.headergov {
    position: fixed;
}
.headergov2 {
  
}
.Govcontainer {
    background-color: white;
    height: 300px !important;
}


.loginMobile {
  display: none;
}
@media screen and (max-width: 900px) {
    .logobogota {
      display: none !important;
    }

    .ContainerAuth {
      display: none;
    }

    .loginMobile {
      display: initial;
      background-color: transparent;
    }

    #full-width-tabpanel-0 {
      background-color: #fff;
    }

    #full-width-tabpanel-1 {
      background-color: #02266C;
      height: 100vh;
    }
  }
body {
    font-family: 'Poppins';
    font-style: normal;
}
.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.fc-day-sat { background: #fcfcfc }
.fc-day-sun { background: #fcfcfc }

.box-aside{
    /* height: 60px; */
    /* left: 710px; */
    /* background: #F3F6F9; */
    border-radius: 11px;
    padding-bottom: -10px;
    width: 70px;
    height: 60px;
}
.mobileTitle {
    display: none;
}

@media (max-width: 900px) {
    .mobileTitle {
        display: initial;
        font-size: 22px;
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
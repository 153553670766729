.custom-body{
    font-family: 'Poppins';
    font-style: normal;
}

.btn-red
{
    background: #BF1231;
    color: #FFFF;
    border-radius: 5%;
    i{
        color: #FFFF;
    }
}
.btn-red:hover{
    background: blue !important;
    color: #FFFF
}

.select_box{
    width: 200px;
    overflow: hidden;
    border: 1px solid #000;
    position: relative;
    padding: 10px 0;
  }
  .select_box:after{
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #f00;
    position: absolute;
    top: 40%;
    right: 5px;
    content: "";
    z-index: 98;
   }
  .select_box select{
    width: 220px;
    border: 0;
    position: relative;
    z-index: 99;
    background: none;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
body {
	font-family: 'Poppins';
	font-style: normal;
}

.cardMobile {
	display: none;
}

.container-menu {
	position: absolute;
	width: 1789px;
	height: 80px;
	left: 130px;
	top: 40px;
}

.h1-custom {
	//position: absolute;
	width: 274px;
	height: 38px;
	left: 210px;
	top: 59px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 38px;
	/* identical to box height, or 158% */
	letter-spacing: -0.02em;

	//color: #000000;
}

.h1-area {
	// width:1080px;
	// height: 80px;
	margin-left: px;
	margin-top: -30px;
	background: #ffffff;
}

.icon-custom-white {
	color: #ffffff;
	font-size: 30px;
	opacity: 0.7;
}

.btn-box-left {
	background: #2266cc;
	border-radius: 6px;
}

.box {
	width: 65px;
	height: 60px;
	left: 710px;
	top: 281px;
	background: #f3f6f9;
	border-radius: 11px;
}

.txt-center {
	text-align: center;
	line-height: 60px;
	font-size: 16px;
	font-weight: 600;
}

.top-icon {
	background-color: #2266cc;
	width: 50px;
	height: 50px;
	position: absolute;
	top: -20px;
	left: 25px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-top {
	font-size: 30px;
	color: #ffffff;
	//opacity: 0.7;
}

.th-custom {
	background-color: #4683de;
	text-align: center;

	//border-radius: 10px 10px 0px 0px;
	h4 {
		color: #ffffff;
	}
}

.td-custom {
	text-align: center;
	color: #000000;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
}

.input-filter {
	box-sizing: border-box;
	width: 472px;
}
.card {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	font-weight: 500;
	font-size: 15px;
	padding: 10px 10px 8px;
}

.card2 {
	height: 695px;
	padding: 5px 5px 0px;
	font-weight: 500;
	font-size: 15px;
}
.blue-card {
	background-color: #2266cc;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 10px 10px 20px;
	height: 180px;
	font-size: 16px;
	display: flex;
	justify-content: center;
	width: 100%;
}
.dark-blue-card {
	background: #153e7b;
	color: #ffffff;
}
.txt-box {
	top: 518px;
	font-size: 15px;
	margin: 0;
	margin-left: 20px;
	font-family: 'Poppins';
	font-style: normal;
}

.titleMobile {
	display: none;
}

@media (max-width: 900px) {
	.table-limit {
		max-width: '400px' !important;
		overflow: scroll !important;
	}
	.tableWeb {
		display: none;
	}
	.titleMobile {
		display: initial;
		font-size: 20px;
		margin-bottom: 12px;
	}
	.inputSeelectMobile {
		padding: 16px;
		font-size: 16px;
	}
	.iconMobile {
		padding: 8px;
	}
	.btnNewUser {
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.paginationWrapper{
    display: flex;
    flex-direction: row;
}

.ShowManyPages {
    display: initial;
}

.paginationChange{
    display: inline-flex;
    align-items: flex-end;
    gap: 16px;
    padding: 12px 0;

    color: #717B93;

    select{
        padding: 8px;
    }
}

.paginationControl {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.pagination {
    display: inline-flex;
    list-style: none;
    padding: 0px;

    li{

        button{
            background-color: transparent;
            border: 0;
            color: #4683DD;
            height: 100%;

            i{
                color: #4683DD;
            }

            &:disabled {
                color: #A6ACBB;

                i{
                    color: #A6ACBB;
                }
            }

        }


        border: 1px solid #A6ACBB;
        padding: 8px;
        margin-left: -1px;

        &:first-child {border-radius: 5px 0 0 5px;}
        &:last-child {border-radius: 0 5px 5px 0;}

        &:hover {
            background-color: #4683DD;
            border: 1px solid #4683DD;

            &:nth-child(3):nth-last-child(3){
                background-color: #FDFEFF;
                border: 1px solid #A6ACBB;
            }

            button{
                background-color: transparent;
                border: 0;
                color: #4683DD;
                height: 100%;

                i{
                    color: #FDFEFF;
                }

                &:disabled {
                    color: #A6ACBB;
                }
            }
        }
    }

    .active {
        background: none;
        font-weight: bold;
        border: 0;
    }

    .pageDescription {
        display: flex;
        flex-direction: column;
        justify-content: center;

        span{
            text-align: center;
            vertical-align: middle;
            color: #717B93;

            &:first-child {font-size: 12px; text-transform: uppercase;}
            &:last-child {font-size: 16px; font-weight: 700;}
        }
    }
}

@media (max-width: 768px) {
    .ShowManyPages {
        display: none !important;
    }
}
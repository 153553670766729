.cardMobile {
	display: none;
}

@media (max-width: 900px) {
	.cardMobile {
		width: 100%;
		height: fit-content;

		display: flex;
		flex-direction: column;
		margin-bottom: 22px;
		padding: 16px;

		box-shadow: 6px 11px 6px -8px rgba(0, 0, 0, 0.1);
		background-color: #f9f9f9;
		border-radius: 10px;

		position: relative;
	}

	.card-body {
	}

	.options {
		top: 15px;
		right: 8px;
		position: absolute;
	}

	.card-title {
		color: #002884;
		font-size: 20px;
		font-weight: bold;
	}

	.card-text {
		color: #000;
		font-size: 16px;
	}
}
